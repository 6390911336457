@import "./variables.module.scss";

.about-description {
    color: $lite !important;
}

.reservationSection {
    background-color: $lite;
}

.backgroundDark {
    background-color: $background-light;
}

.about-title {
    font-family: "Bebas Neue", sans-serif;
    font-size: 50px !important;
}

.category-name-font {
    font-family: "Cairo", sans-serif !important;
}

.reservation-title {
    font-family: "Exo 2", sans-serif;
    font-style: italic;
    font-size: 40px !important;
    color: $primary;
}

h3.title-big {
    font-family: "Fugaz One", sans-serif;
    font-family: 37px !important;
}

.fugaz-font {
    font-family: "Bebas Neue", sans-serif;
    font-size: 45px !important;
}

.w3l-footer-29-main h6.footer-title-29 {
    font-family: "Exo 2", sans-serif;
    font-size: 28px !important;
    font-style: italic;
}

.exo2 {
    font-family: "Raleway", sans-serif;
    font-size: 40px !important;
}
.profile-padding {
    padding: 15px;
    font-size: 20px;
}
.profile-margin {
    margin-bottom: 5px;
}

.footer-text {
    color: $primary;
}

.reservation-hear-about-text {
    color: $primary !important;
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold !important;
}

.point-text {
    margin-top: 100px !important;
    margin-bottom: 80px !important;
    font-size: 25px !important;
    color: $primary !important;
}

.point-value {
    font-size: 45px !important;
}

.point-link {
    font-size: 16px !important;
    margin-top: 20px !important;
}

.pop-over {
    color: $lite;
    cursor: pointer;
    font-size: large;
}

.pop-over-text{
    font-size: 18px !important;
    padding: 5px !important;
    
}
